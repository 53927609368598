import moment from "moment";
import numeral from "numeral";

export function formatToThaiDateTime(
  dateTimeString: string,
  includeTime: boolean = true
) {
  const thaiMonths = [
    "ม.ค.",
    "ก.พ.",
    "มี.ค.",
    "เม.ย.",
    "พ.ค.",
    "มิ.ย.",
    "ก.ค.",
    "ส.ค.",
    "ก.ย.",
    "ต.ค.",
    "พ.ย.",
    "ธ.ค.",
  ];

  const dt = moment(dateTimeString);
  const formattedDate = `${dt.date()} ${thaiMonths[dt.month()]} ${
    dt.year() + 543
  }`;
  const formattedTime = includeTime ? `, ${dt.format("HH:mm")}` : "";

  return `${formattedDate}${formattedTime}`;
}

export const getStorageAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");

  if (accessToken) {
    return accessToken;
  }

  return null;
};

export const formatMoney = (amount: number) => {
  return numeral(amount).format("0,0.00");
};
