import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { getStorageAccessToken } from './common';
import { getApiKeyHeaders } from '@/libs/utils';
import { ApiKeyHeaders } from '@/@types/util';
type FetchOptions = AxiosRequestConfig & {
  body?: any;
  params?: any;
};

const fetcher = async <T>(url: string, options?: FetchOptions): Promise<T> => {
  const xApiKeyHeaders: ApiKeyHeaders = getApiKeyHeaders();
  const accessToken = getStorageAccessToken();
  const headers = {
    ...options?.headers,
    ...(accessToken && {
      Authorization: `Bearer ${accessToken}`,
    }),
    agent: xApiKeyHeaders.agent,
    time: xApiKeyHeaders.time.toString(),
    'X-Api-Key': xApiKeyHeaders['X-Api-Key'],
  };

  const response = await axios({
    method: options?.method || 'get',
    url,
    headers,
    data: options?.body,
    params: options?.params,
  });

  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error(`Request failed with status ${response.status}`);
  }
};

export default fetcher;
