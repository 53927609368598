import React, { Fragment } from "react";
import numeral from "numeral";

// mui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/Button";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import DialogContent from "@mui/material/DialogContent";

// mui icon
import CloseIcon from "@mui/icons-material/Close";

interface ResponsiveDialogProps {
  cashbackData: CashbackData | null;
  open: boolean;
  closeDialog: () => void;
  getClaimCashback: () => void;
}

// type
import { CashbackData } from "@/@types/refound";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: 500,
  overflow: "scroll",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const TermsDialog: React.FC<ResponsiveDialogProps> = ({
  cashbackData,
  open,
  closeDialog,
  getClaimCashback,
}) => {
  return (
    <Fragment>
      <Modal
        open={open}
        onClose={closeDialog}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            borderRadius: (theme) => theme.shape.borderRadius + "px",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <Grid sx={{ mb: 1, mt: 5 }}>
                        <Button variant="contained" color='info' fullWidth> <Typography variant='h5' color='white'>คืนยอดเสีย</Typography></Button>
                    </Grid> */}
          <Card sx={{ mb: 3 }}>
            <CardContent>
              <Typography variant="h5" sx={{ color: (theme) => theme.gradient[900] }}>รับยอดเสีย</Typography>
              <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 2, md: 2 }}
              >
                <Grid xs={6} sm={6} md={6}>
                  <Typography fontSize="small" sx={{ color: (theme) => theme.gradient[900] }}>
                    ยอดเสียสะสม{" "}
                    {numeral(cashbackData?.cashback).format("0,0.00")}
                  </Typography>
                </Grid>
                <Grid xs={6} sm={6} md={6}>
                  <Typography fontSize="small" sx={{ color: (theme) => theme.gradient[900] }}>
                    Cashback{" "}
                    {numeral(cashbackData?.cashback_profit).format("0,0.00")}%
                  </Typography>
                </Grid>
              </Grid>
              <Typography sx={{ mt: 3, mb: 3, textAlign: "center", color: (theme) => theme.gradient[900] }}>
                ยอดเสียสะสม
              </Typography>
              <Typography variant="h3" sx={{ mb: 3, textAlign: "center", color: (theme) => theme.gradient[900] }}>
                {numeral(cashbackData?.cashback).format("0,0.00")}
              </Typography>
              {cashbackData?.cashback_dayofweek !==
                cashbackData?.day_of_week && (
                <Button
                  variant="contained"
                  // color="error"
                  fullWidth
                  onClick={getClaimCashback}
                >
                  {" "}
                  ยอดเสียของคุณสามารถรับได้ใน {cashbackData?.day_text}
                </Button>
              )}
              {cashbackData?.cashback_dayofweek ===
                cashbackData?.day_of_week && (
                <Button
                  onClick={getClaimCashback}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  <Typography sx={{ color: (theme) => theme.gradient[900] }}>รับเงินคืน</Typography>{" "}
                </Button>
              )}
            </CardContent>
          </Card>
          <Card>
            <CardContent>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>กฏการรับกิจกรรมการถอน “ยอดเสียคืน”</Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                1. กดรับคืน “ยอดเสีย” ได้ทุก{cashbackData?.day_text} เวลา
                08.00-23.59น.
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                2. กรณีถอนเข้า “กระเป๋าเครดิต”
                จะต้องมียอดเสียที่ได้รับขั้นต่ำ1บาท และ
                มียอดในกระเป๋าเครดิตต่ำกว่า 10 บาท ถึงจะถอนได้
              </Typography>
              <Typography sx={{ mt: 5, mb: 5, color: (theme) => theme.gradient[900] }}>
                กฏกติกา “รับยอดเสียคืน”
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                1. ก่อนกดรับ “ยอดเสียคืน” ควรมียอดเงินคงเหลือน้อยกว่า 10 บาท
                มีฉะนั้นตอนถอนเงินจะติดเงื่อนไขถอนสูงสุด 10
                เท่าของยอดเสียคืนที่ได้รับ
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                2. “ยอดเสียคืน” ที่ได้รับ ต้องทำเทิร์น 2 เท่า ถอนได้ทันที
                ถอนสูงสุด 10 เท่าของยอดเสีย
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                3. “ยอดเสียคืน” รับได้สูงสุดครั้งละ 100,000 บาท
              </Typography>
              <Typography sx={{ color: (theme) => theme.gradient[900] }}>
                4.
                หากพบเห็นการเดิมพันที่ผิดปกติระบบจะทำการตัดเครดิตโดยอัตโนมัติทันทีและไม่สามารถคืนเงินได้
              </Typography>
            </CardContent>
          </Card>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default TermsDialog;
